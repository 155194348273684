import {useState} from 'react';
import SelectInput from '../../common/SelectInput';
import FormWrapper from '../../common/StyledFormWrapper';
import TextInput from '../../common/TextInput';
import {Alert, Box, Grid, Typography} from '@mui/material';
import {sendMeetingInvite} from '../../../api';
import {CONSTANTS} from '../../../constants';
import DatePicker from '../../common/DatePicker';
import useLeaderStore from '../../../store/leaderStore';
import moment from 'moment';
import TimePickerComponent from '../../common/TimePicker';
import useTranslations from '../../../hooks/useTranslation';

const PageFour = ({groupData, nextPage}) => {
  const {translate} = useTranslations();
  const [weeks, setWeeks] = useState(48);
  const [dayOfWeek, setDayOfWeek] = useState('monday');
  const [selectedDate, setSelectedDate] = useState(null);
  const [meetingPlace, setMeetingPlace] = useState('');
  const [timeToMeet, setTimeToMeet] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const [error, setError] = useState(null);
  const {setMeetings} = useLeaderStore(state => state);

  const weekDays = [
    {label: translate("Monday"), value: 'monday'},
    {label: translate("Tuesday"), value: 'tuesday'},
    {label: translate("Wednesday"), value: 'wednesday'},
    {label: translate("Thursday"), value: 'thursday'},
    {label: translate("Friday"), value: 'friday'},
    {label: translate("Saturday"), value: 'saturday'},
    {label: translate("Sunday"), value: 'sunday'}
  ];
  const allWeeks = [
    {label: '42', value: 42},
    {label: '43', value: 43},
    {label: '44', value: 44},
    {label: '45', value: 45},
    {label: '46', value: 46},
    {label: '47', value: 47},
    {label: '48', value: 48}
  ];

  const isValidMeetingPlace = !!meetingPlace.trim();
  const isValidDate = !!selectedDate;
  const isValidDayOfWeek = !!dayOfWeek;
  const isValidWeeks = weeks >= 1 && weeks <= 100;

  const isValidForm = isValidMeetingPlace && isValidDate && isValidDayOfWeek && isValidWeeks;

  const sendMeetingInviteHandler = async data => {
    const meetingData = {
      ...data,
      group_id: groupData?.id
    };
    try {
      setLoading(true);
      const res = await sendMeetingInvite(meetingData);
      if (res?.data?.status === 200) {
        setMeetings(res?.data?.data);
        nextPage();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (isValidForm) {
      sendMeetingInviteHandler({
        meeting_day: dayOfWeek,
        starting_time: moment(selectedDate).format('YYYY-MM-DD'),
        meeting_place: meetingPlace,
        meeting_weeks: weeks,
        meeting_time: timeToMeet
      });
    } else {
      setError(translate("All fields are required!"));
    }
  };

  const disabledDates = date => {
    let dayOfWeeks = dayOfWeek;
    let weekNo;
    if (dayOfWeeks === 'monday') {
      weekNo = 1;
    }
    if (dayOfWeeks === 'tuesday') {
      weekNo = 2;
    }
    if (dayOfWeeks === 'wednesday') {
      weekNo = 3;
    }
    if (dayOfWeeks === 'thursday') {
      weekNo = 4;
    }
    if (dayOfWeeks === 'friday') {
      weekNo = 5;
    }
    if (dayOfWeeks === 'saturday') {
      weekNo = 6;
    }
    if (dayOfWeeks === 'sunday') {
      weekNo = 0;
    }
    return new Date(date).getDay() !== weekNo;
  };

  return (
    <Box mt={1.5}>
      <FormWrapper buttonInputs={[{text: translate("Let's go"), onClick: handleNextPage}]} headingText={translate("The Meeting")} loading={loading}>
        <SelectInput
          placeholder={translate("Select a Day of the Week")}
          label={translate("What Day Will You Meet?")}
          value={dayOfWeek}
          setValue={value => {
            setSelectedDate(null);
            setDayOfWeek(value);
          }}
          optionArray={weekDays}
          error={!isValidDayOfWeek}
          helperText={!isValidDayOfWeek && translate("Please select a day")}
        />
        <TimePickerComponent
          label={translate("WHAT TIME WILL YOU MEET?")}
          onChange={e => {
            setError(null);
            setTimeToMeet(moment(e));
          }}
          value={moment(timeToMeet)}
        />
        <Box mt={1.5}>
          <TextInput
            placeholder={translate("Ex. My Living Room, Our Church")}
            label={translate("Where Will You Meet?")}
            value={meetingPlace}
            onChange={e => {
              setError(null);
              if (meetingPlace?.length <= 64) {
                setMeetingPlace(e.target.value);
              }
            }}
            error={!isValidMeetingPlace}
            labelVariant="body2"
          />
        </Box>
        <Box mt={1.5}>
          <Typography textAlign="left">{translate("WHAT DAY WILL YOU START")}</Typography>
          <DatePicker
            open={dateOpen}
            setOpen={() => setDateOpen(true)}
            onClose={() => setDateOpen(false)}
            value={selectedDate}
            onChange={newValue => {
              setError(null);
              setSelectedDate(newValue);
            }}
            minDate={moment(new Date()).subtract(1, 'year')}
            error={!isValidDate}
            showDaysOutsideCurrentMonth
            shouldDisableDate={disabledDates}
          />
        </Box>
        <Box mt={1.5} sx={{textAlign: 'left'}}>
          <Typography variant="body2">{translate("HOW MANY WEEKS WILL YOU MEET?")}</Typography>
          <Box
            mt={1.5}
            display={'flex'}
            sx={{
              '& >[class^=css-]': {
                width: 'unset'
              }
            }}
            alignItems={'center'}
          >
            <Box sx={{xs: {width: '25%'}, md: {width: '9%'}}}>
              <SelectInput
                value={weeks}
                setValue={value => {
                  setWeeks(value);
                }}
                optionArray={allWeeks}
              />
            </Box>
            <Box ml={2} mb={1}>
              <Typography variant="body3" fontWeight="400" fontSize={14}>
                {translate("48* weeks is recommended under most circumstances")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid item xs={12} sx={{height: '50px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </FormWrapper>
    </Box>
  );
};

export default PageFour;
