import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';
import Modal from '../../../components/common/Modal';
import {styled} from '@mui/material/styles';
import {Button} from '@mui/material';
import {Edit} from '@mui/icons-material';
import {updateGroupApi} from '../../../api';
import Loader from '../../../components/common/Loader';
import NoDataFoundMessage from '../../../components/common/NoDataFoundMessage';
import useTranslations from '../../../hooks/useTranslation';

const StyledSelect = styled('select')({
  width: '100%',
  padding: '10px',
  margin: '10px 0 20px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
  outline: 'none',
  '&:focus': {
    borderColor: '#d32f2f'
  }
});

const ItemDetails = ({title, details, leaders, message, height, selectedGroup, loader, setSelectedGroup}) => {
  const {translate} = useTranslations();
  const [newLeaderId, setNewLeaderId] = useState();
  const [editLeader, setEditLeader] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState('');
  let toReplaceLeader = leaders?.find(leader => leader.name.trim() === newLeaderId);

  const handleReplaceLeader = async () => {
    try {
      const resp = await updateGroupApi(selectedGroup?.id, {
        replace_leader: toReplaceLeader?.id
      });
      if (resp?.data?.status == 200) {
        setSelectedGroup();
      }
    } catch (error) {}
  };
  const handleEditLeaderOpenModal = id => {
    setSelectedLeader(id);
    setEditLeader(true);
  };
  const handleEditItemCloseModal = () => {
    handleReplaceLeader();
    setEditLeader(false);
  };

  const getUserById = id => {
    return leaders?.find(leader => leader.user_id == id);
  };

  return (
    <Box
      p={3}
      overflow="scroll"
      bgcolor="#FFF"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap={1}
      textAlign="left"
      sx={{
        height: {
          md: height,
          xs: 'auto',
          sm: 'auto'
        }
      }}
    >
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold">
            {title}
          </Typography>
          {details.length === 0 ? (
            <NoDataFoundMessage mt={5} message={translate("No disciples under this leader.")} />
          ) : (
            details?.map((detail, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="body1"
                  color="black"
                  fontWeight={600}
                  mr={1}
                  sx={{
                    '& .nth-child(2)': {
                      color: 'red'
                    }
                  }}
                >
                  {detail?.name}
                </Typography>
                {detail.detail && (
                  <Typography variant="body2" color="black" sx={{marginBottom: '0px !important'}}>
                    {detail.detail}
                  </Typography>
                )}
                {detail?.leaders && (
                  <Typography variant="body2" color="black" sx={{marginBottom: '0px !important'}}>
                    {detail?.leaders?.name}
                  </Typography>
                )}
                {detail?.icon && (
                  <IconButton
                    onClick={() => {
                      handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
                {message && (
                  <Typography variant="body2" color="black" ml={1}>
                    {message}
                  </Typography>
                )}
              </div>
            ))
          )}
          <Modal backgroundColor="white" color="black" open={editLeader} onClose={handleEditItemCloseModal} title={translate("Confirm Change")}>
            <Box mb={1} ml={1}>
              <Typography variant="body1" mb={1} color="black">
                {translate("Are you sure you want to assign a new leader to this group?")}
              </Typography>

              <StyledSelect
                value={newLeaderId}
                onChange={e => {
                  getUserById(e.target.value);
                  setNewLeaderId(e.target.value);
                }}
              >
                  {leaders &&
                    leaders.map((leader,i) => {
                      const index = leaders.findIndex(leader => leader.id == selectedLeader);
                      return (
                        <>
                          {index == -1 && i ==0 && (
                            <option key="null" value={null} disabled={true} selected={index == -1 && leader.user_id !== selectedLeader}>
                              {translate("Deleted User")}
                            </option>
                          )}
                          {console.log(leader.user_id === selectedLeader,leader, selectedLeader)}
                          {leader.status == 'ACTIVE' && <option key={leader.user_id} value={leader.user_id} disabled={leader.id === selectedLeader} selected={leader.id === selectedLeader}>
                            {leader.name ? leader.name : translate("Deleted User")}
                          </option>}
                        </>
                      );
                    })
                  }

              </StyledSelect>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="error" onClick={() => setEditLeader(false)} sx={{mr: 1}}>
                  {translate("Cancel")}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleEditItemCloseModal} disabled={!newLeaderId}>
                  {translate("Replace")}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default ItemDetails;
