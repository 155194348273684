import React, {useState} from 'react';
import {Box, List, ListItem, ListItemText, IconButton, Menu, MenuItem, FormControlLabel, Checkbox, Typography, Button, useMediaQuery} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import {styled} from '@mui/material/styles';
import {addUserRoleApi, deleteUser, removeUserRoleApi} from '../../../api/index';
import Modal from '../../../components/common/Modal';
import {capitalizeWords} from '../../../utils';
import {CONSTANTS} from '../../../constants';
import NoDataFoundMessage from '../../../components/common/NoDataFoundMessage';
import useTranslations from '../../../hooks/useTranslation';

const ScrollBox = styled(Box)({
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '25px',
    backgroundColor: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '25px',
    backgroundColor: '#888'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555'
  }
});

const PromotionsList = ({title, initialItems, height, showDeleteIcon, user, width, menuList, getDataHandler, setLoader, organizationSelected}) => {
  const {translate} = useTranslations();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [items, setItems] = useState(initialItems);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleMenuOpen = (event, itemId) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(itemId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteUser(itemToDelete);
      if (response?.data?.status === 200) {
        setItems(items.filter(i => i.id !== itemToDelete));
        getDataHandler()
      }
      handleCloseModal();
    } catch (error) {
      handleCloseModal();
    }
  };

  const handleOpenModal = itemId => {
    setItemToDelete(itemId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setItemToDelete(null);
    setIsModalOpen(false);
  };

  const handleCheckboxChange = async (id, role, type) => {
    const permission = CONSTANTS.USER_ROLES[role];
    let res;
    setLoader(true);
    try {
      if (type === 'add') {
        res = await addUserRoleApi({user_id: id, permission, orgId: organizationSelected});
      } else {
        res = await removeUserRoleApi(id, permission, organizationSelected);
      }
      if (res.data.status === 200) {
        getDataHandler();
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <ScrollBox
      width={width}
      bgcolor="#fff"
      p={2}
      borderRadius="12px"
      height={height}
      minHeight={!isSmallScreen ? '44%' : 'auto'}
      overflow="auto"
    >
      <Typography variant="h6" fontWeight="bold" color="#d32f2f" textAlign="left" ml={2} mb={2}>
        {title}
      </Typography>
      <List>
        {items.length === 0 && <NoDataFoundMessage mt={5} message={translate("No data available.")} />}
        {items.map(item => (
          <ListItem
            key={item.id}
            secondaryAction={
              <>
                <IconButton onClick={event => handleMenuOpen(event, item.id)}>
                  <MoreVertIcon />
                </IconButton>
                {showDeleteIcon && (
                  <IconButton onClick={() => handleOpenModal(item.id)} disabled={item.id === user?.id}>
                    <DeleteIcon
                      sx={{
                        color: item.id !== user?.id ? '#d32f2f' : '#ccc'
                      }}
                    />
                  </IconButton>
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedItemId === item.id}
                  onClose={handleMenuClose}
                  sx={{
                    '& .MuiPaper-root': {
                      backgroundColor: 'white',
                      color: 'black',
                      height: '120px'
                    }
                  }}
                >
                  {menuList?.map(option => (
                    <MenuItem style={{backgroundColor: 'white', color: 'black'}} key={option.value}>
                      <FormControlLabel
                        sx={{
                          '& .MuiFormControlLabel-label': {color: 'black'}
                        }}
                        id='user_permission'
                        htmlFor='user_permission'
                        control={
                          <Checkbox
                            id='user_permission'
                           checked={typeof item.user_permissions === 'string' && item.user_permissions.split(',').includes(String(CONSTANTS.USER_ROLES[option.value]))}
                           disabled={item.id === user?.id && option.value === 'ADMIN'}
                            onChange={() => {
                              if (!(item.id === user?.id && option.value === 'ADMIN')) {
                                if (item?.user_permissions?.split(',').includes(String(CONSTANTS.USER_ROLES[option.value]))) {
                                  handleCheckboxChange(item.id, option.value, 'remove');
                                } else {
                                  handleCheckboxChange(item.id, option.value, 'add');
                                }
                              }
                            }}
                          />
                        }
                        label={translate(option.label)}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            }
          >
            <ListItemText
              primary={item.name}
              primaryTypographyProps={{
                sx: {
                  color: '#000'
                }
              }}
            />
          </ListItem>
        ))}
      </List>
      <Modal backgroundColor="white" color="black" open={isModalOpen} setOpen={setIsModalOpen} title="Confirm Delete">
        <Box>
          <Typography variant="body1" mb={4} color="black">
            {translate("Are you sure you want to delete this user?")}
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" color="error" onClick={handleCloseModal} sx={{mr: 1}}>
              {translate("Cancel")}
            </Button>
            <Button variant="contained" color="secondary" onClick={handleDelete}>
              {translate("Delete")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </ScrollBox>
  );
};

export default PromotionsList;
