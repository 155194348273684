import React, {useState, useEffect} from 'react';
import {Box, Typography, useMediaQuery} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {getAdminOrgDisciplesApi, getDiscipleDetailsApi} from '../../api';
import AdminStructure from './common/AdminStructure';
import useAdminStore from '../../store/adminStore';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import {CONSTANTS} from '../../constants';
import useTranslations from '../../hooks/useTranslation';

function Disciples() {
  const {translate} = useTranslations();
  const {organizationSelected} = useAdminStore(state => state);
  const [selectedDisciple, setSelectedDisciple] = useState(null);
  const [adminDisciple, setAdminDisciple] = useState([]);
  const [discipleData, setDiscipleData] = useState([]);
  const [searchDisciples, setSearchDisciples] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleDiscipleClick = async disciple => {
    setLoader(true);
    setSelectedDisciple(disciple);
    const resp = await getDiscipleDetailsApi(disciple?.id, 'disciple');
    if (resp.status == 200) {
      setDiscipleData(resp?.data?.data?.[0]);
      setLoader(false);
    }
    setLoader(false);
  };

  const getDisciples = async () => {
    setLoader(true);
    try {
      setPageLoading(true);
      const response = await getAdminOrgDisciplesApi(organizationSelected, 'disciple');
      if (response?.data?.status == 200) {
        setAdminDisciple(response?.data?.data);
        setSearchDisciples(response?.data?.data);
      }
      setPageLoading(false);
      setLoader(false);
    } catch (error) {
      setPageLoading(false);
      setLoader(false);
    }
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setAdminDisciple(searchDisciples);
    } else {
      const filteredDisciples = searchDisciples.filter(disciple => disciple?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminDisciple(filteredDisciples);
    }
  };

  useEffect(() => {
    getDisciples();
  }, [organizationSelected]);

  // Calculate the counts of each status disciple
  const activeDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => disciple.status === 'ACTIVE')?.length : 0;
  const invitedDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => ['COMPLETED', 'ARCHIVED'].includes(disciple.status))?.length : 0;

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden',
        overflowX: 'hidden'
      }}
    >
      <AdminStructure isSearch={true} searchHandler={searchHandler} heading={translate("Disciples")}>
        {pageLoading ? (
          <Loader color="#9e0001" />
        ) : adminDisciple?.length === 0 ? (
          <NoDataFoundMessage mt={5} message={translate("There are no disciples in this organization")} />
        ) : (
          <Box
            display="flex"
            // justifyContent="space-between"
            px={2}
            gap={2}
            sx={{
              height: {
                md: '85vh',
                xs: 'auto',
                sm: 'auto'
              },
              flexDirection: {
                md: 'row',
                sm: 'column',
                xs: 'column'
              },
              justifyContent: {
                md: 'space-between'
              }
            }}
          >
            <Box width="100%" display={'flex'} flexDirection={'column'} gap={2}>
              <ItemList items={adminDisciple.filter(disc => ['ACTIVE'].includes(disc.status))} handleClick={handleDiscipleClick} selectedItem={selectedDisciple} height={'50%'} title={`${translate("Active Disciples")} (${activeDisciplesCount})`} />
              <ItemList items={adminDisciple.filter(disc => ['COMPLETED', `ARCHIVED`, `INVITED`].includes(disc.status))} handleClick={handleDiscipleClick} selectedItem={selectedDisciple} height={'50%'} title={`${translate("Invited Disciples")} (${invitedDisciplesCount})`} />
            </Box>

            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              // justifyContent="space-between"
              gap={2}
              sx={{
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              {selectedDisciple && (
                <ItemDetails
                  title={selectedDisciple.name}
                  selectedLeader={selectedDisciple}
                  height="42%"
                  details={[
                    {
                      name: translate("Date Finished D1:"),
                      detail: discipleData?.d1_completion_date !== 'N/A' ? moment(discipleData?.d1_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: translate("Date Finished D2:"),
                      detail: discipleData?.d2_completion_date !== 'N/A' ? moment(discipleData?.d2_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: translate("Date Finished D3:"),
                      detail: discipleData?.d3_completion_date !== 'N/A' ? moment(discipleData?.d3_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A'
                    },
                    {
                      name: discipleData?.groupsLed,
                      detail: translate("Groups Led")
                    },
                    {
                      name: discipleData?.disciplesMade,
                      detail: translate("Disciples Made")
                    }
                    // {
                    //   name: selectedDisciple?.disciplesMade || 0,
                    //   detail: "Disciples Made",
                    // },
                    // {
                    //   name: "Approved to Lead D2:",
                    //   detail: "",
                    // },
                  ]}
                  loader={loader}
                />
              )}
              {loader ? (
                <Loader color="#9e0001" />
              ) : (
                <>
                  {selectedDisciple && (
                    <>
                      <Box p={3} height="42%" overflowY="scroll" bgcolor="#FFF" borderRadius={5} display="flex" flexDirection="column" textAlign="left">
                        <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold">
                          {translate("Who is")} {selectedDisciple?.name}
                        </Typography>
                        <div
                          //  key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: '0.5rem'
                          }}
                        >
                          {discipleData?.title !== '' ? (
                            discipleData?.title?.split(',').map((item, index, array) =>
                              index === 2 ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {[2, 3, 4].map(i => array[i]).join(' • ')}
                                </Typography>
                              ) : index === array.length - 1 ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {`${item} ${translate("on the Pathway")}`}
                                </Typography>
                              ) : ![3, 4].includes(index) ? (
                                <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                  {item}
                                </Typography>
                              ) : null
                            )
                          ) : (
                            <NoDataFoundMessage mt={5} message={translate("No assessment data available.")} />
                          )}
                        </div>
                      </Box>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </AdminStructure>
    </div>
  );
}

export default Disciples;
