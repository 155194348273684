import React, {useState, useEffect, useRef} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import {Typography, Grid, Alert, InputLabel, Avatar, IconButton} from '@mui/material';
import TextInput from '../../common/TextInput';
import {useNavigate} from 'react-router-dom';
import {addAddressApi, getAddressApi, getAddressByZipcode} from '../../../api';
import {CONSTANTS, BASE_URL, FILE_PATH_PROFILE_URL} from '../../../constants';
import DatePicker from '../../common/DatePicker';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import {validatePhone, isValidFile} from '../../../utils';
import useAuthStore from '../../../store/authStore';
import useTranslations from '../../../hooks/useTranslation';

const DiscipleAddress = ({setPage}) => {
  const { translate } = useTranslations();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    zipcode: '',
    city: '',
    state: '',
    country: '',
    birthday: '',
    salvation_year: '',
    mobile_number: '',
    avatar: ''
  });
  const {user, token} = useAuthStore(state => state);
  const [dateOpen, setDateOpen] = useState(false);
  const [dateOpen2, setDateOpen2] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const zipRef = useRef();
  const navigate = useNavigate();

  const handleChange = e => {
    setErrorMsg(null);
    const {name, value} = e.target;
    if(name === "birthday" && formData.salvation_year && moment(formData.salvation_year).isBefore(formData.birthday)) {
      setErrorMsg(translate("Salvation year must be after birthday"));
    }else {
      setErrorMsg("");
    }
    if (name === 'zipcode') {
      const trimmedValue = value.replace(/[^a-zA-Z0-9-]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: trimmedValue
      }));
    } else if (name === 'city' || name === 'state' || name === 'country') {
      const trimmedValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: trimmedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handlePhoneInputChange = e => {
    setErrorMsg(null)
    const phoneNumber = e.target.value;
    const regex = /^[0-9]*$/;

    if (!regex.test(phoneNumber)) {
      return;
    }
    setFormData(prevData => ({
      ...prevData,
      mobile_number: phoneNumber
    }));
  };

  const imageChangeHandler = e => {
    let file = e.target.files[0];
    if (file.size >= CONSTANTS.MAX_IMAGE_FILE_SIZE) {
      setErrorMsg(translate("File is too large (not more than 10 mb)"));
      return;
    }
    if (isValidFile('image', file)) {
      setFormData(prevData => ({
        ...prevData,
        avatar: e.target.files[0]
      }));
    } else {
      setErrorMsg(translate("Only image file allowed!"));
    }
  };

  const saveAddress = async completeAddress => {
    try {
      const updatedAdress = {
        ...completeAddress,
        // birthday: moment(completeAddress.birthday).format('YYYY-MM-DD'),
        // salvation_year: moment(completeAddress.salvation_year).get('year').toString()
      };
      setLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append('zipcode', updatedAdress.zipcode);
      formDataToSend.append('country', updatedAdress.country);
      formDataToSend.append('city', updatedAdress.city);
      formDataToSend.append('state', updatedAdress.state);
      formDataToSend.append('birthday', moment(updatedAdress.birthday).format("YYYY-MM-DD") || '');
      formDataToSend.append('salvation_year', moment(updatedAdress.salvation_year).get('year').toString());
      formDataToSend.append('mobile_number', updatedAdress.mobile_number );

      if (formData.avatar && typeof formData.avatar !== 'string') {
        formDataToSend.append('profile_avatar', formData.avatar);
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: formDataToSend
      };
      const response = await fetch(`${BASE_URL}/address`, requestOptions);
      const data = await response.json();
      if (data?.status === 200) {
        toast.success(translate("User updated successfully!"), {
          id: 'toast_message'
        });
        setPage(2);
      } else {
        toast.error(translate("Something went wrong!"), {
          id: 'error_message'
        });
      }
    } catch (error) {
      setLoading(false);
      setPage(2);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!String(formData.zipcode).trim() || !String(formData.country).trim() || !String(formData.city).trim() || !String(formData.state).trim() || !formData.birthday || !formData.salvation_year || !formData.mobile_number) {
      setErrorMsg(translate("All fields are required!"));
      return;
    } else {
      if (!validatePhone(formData.mobile_number)) {
        setErrorMsg(translate("Please enter valid phone number!"));
        return;
      }
      saveAddress(formData);
    }
  };

  const getZipCodeHandler = async () => {
    try {
      setLoading(true);
      const response = await getAddressByZipcode(formData?.zipcode);
      if (response.data.status === 200) {
        setFormData(prev => {
          return {
            ...prev,
            city: response?.data?.data?.city,
            state: response?.data?.data?.state,
            country: response?.data?.data?.county_area
          };
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getAddressHandler = async () => {
    try {
      setLoading(true);
      const response = await getAddressApi();
      if (response?.data?.status === 200) {
        setFormData({
          zipcode: response?.data?.data?.zipcode,
          city: response?.data?.data?.city,
          state: response?.data?.data?.state,
          country: response?.data?.data?.country,
          birthday: response?.data?.data?.birthday,
          salvation_year: response?.data?.data?.salvation_year,
          mobile_number: response?.data?.data?.mobile_number,
          avatar: response?.data?.data?.avatar
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (String(formData?.zipcode).length === 5) {
      getZipCodeHandler();
    }
  }, [formData.zipcode]);

  useEffect(() => {
    getAddressHandler();
  }, []);

  return (
    <>
      <CssBaseline />
      <HeaderContainer pt={1} mb={4}>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate('/leader')} color="white" />
      </HeaderContainer>
      <Typography variant="h3">{translate("Tell Us About You")}</Typography>
      <Typography variant="body1" style={{marginTop: '20px'}}>
        {translate("YOUR JOURNEY")} &bull; {translate("1 OF")} {user.user_role === 'LEADER' ? '4' : '3'}
      </Typography>
      <form style={{width: '100%', marginTop: '30px'}} onSubmit={handleSubmit}>
        <IconButton>
          <label style={{display: 'flex', cursor: 'pointer'}} htmlFor="contained-button-file">
            <div style={{position: 'relative'}}>
              <Avatar
                alt={formData?.name}
                src={formData?.avatar ? (typeof formData?.avatar === 'object' ? URL.createObjectURL(formData?.avatar) : `${FILE_PATH_PROFILE_URL}/${formData?.avatar}`) : ''}
                style={{
                  margin: '10px',
                  width: '80px',
                  height: '80px'
                }}
              />
              <EditIcon
                style={{
                  color: 'black',
                  position: 'absolute',
                  bottom: 20,
                  right: 19,
                  transform: 'translate(50%, 50%)',
                  zIndex: 1,
                  backgroundColor: 'whitesmoke',
                  borderRadius: '50%',
                  padding: '5px'
                }}
              />
            </div>
            <input onChange={imageChangeHandler} style={{display: 'none'}} accept="image/*" id="contained-button-file" type="file" />
          </label>
        </IconButton>
        <Grid container spacing={1}>
          <Grid item xs={12}>
          <InputLabel>{translate("WHERE ARE YOU LOCATED")}</InputLabel>
            <TextInput
              name="zipcode"
              value={formData.zipcode}
              autoComplete="off"
              onChange={handleChange}
              onInput={e => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
              }}
              placeholder={translate("Zip Code")}
              maxLength={CONSTANTS.ZIPCODE_MAX_LENGTH}
              inputRef={zipRef}
              labelVariant="body2"
              inputProps={{maxLength: CONSTANTS.ZIPCODE_MAX_LENGTH}}
              isAllowSpecialChar
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput name="city" value={formData.city} autoComplete="off" onChange={handleChange} placeholder={translate("City")} maxLength={CONSTANTS.LOCATION_MAX_LENGTH} labelVariant="body2" />
          </Grid>
          <Grid item xs={12}>
            <TextInput name="state" value={formData.state} autoComplete="off" onChange={handleChange} maxLength={CONSTANTS.LOCATION_MAX_LENGTH} placeholder={translate("State")} labelVariant="body2" />
          </Grid>
          <Grid item xs={12}>
            <TextInput name="country" value={formData.country} autoComplete="off" onChange={handleChange} maxLength={CONSTANTS.LOCATION_MAX_LENGTH} placeholder={translate("Country")} labelVariant="body2" />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{translate("YOUR BIRTHDAY")}</InputLabel>
            <DatePicker open={dateOpen} setOpen={() => setDateOpen(true)} onClose={() => setDateOpen(false)} name="birthday" value={formData?.birthday ? moment(formData?.birthday) : ''} onChange={date => handleChange({target: {name: 'birthday', value: date}})} maxDate={moment(new Date()).subtract(18, 'year')} />
          </Grid>
          <Grid item xs={12} >
            <InputLabel>{translate("YOUR SALVATION YEAR")}</InputLabel>
            <DatePicker
              open={dateOpen2}
              setOpen={() => setDateOpen2(true)}
              onClose={() => setDateOpen2(false)}
              views={['year']}
              format={'YYYY'}
              sx={{width: '100%'}}
              name="salvation_year"
              value={formData?.salvation_year ? moment(formData?.salvation_year, 'YYYY') : ''}
              onChange={date =>
                handleChange({
                  target: {name: 'salvation_year', value: date ? date.format('YYYY') : ''}
                })
              }
              minDate={formData.birthday ? moment(formData.birthday) : moment(new Date()).subtract(120, 'years')}
              maxDate={moment(new Date()).add(10, 'years')}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <InputLabel>{translate("PHONE NUMBER")}</InputLabel>
            <TextInput name="mobile_number" value={formData.mobile_number} placeholder={translate("Phone Number")}  maxLength={CONSTANTS.MOBILE_NUMBER_MAX_LENGTH} onChange={handlePhoneInputChange} autoComplete="off" labelVariant="body2" />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{height: '50px'}}>
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        </Grid>

        <Button type="submit" fullWidth variant="contained" disabled={loading} color="secondary" style={{marginTop: '20px', fontWeight: 400, marginBottom: '20px'}}>
          {translate("Continue")}
        </Button>
      </form>
    </>
  );
};

export default DiscipleAddress;
