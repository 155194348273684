import {Box, Typography, IconButton, Grid, Alert} from '@mui/material';
import React, {useEffect, useState} from 'react';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import theme from '../../../styles/theme';
import {CssBaseline} from '@mui/material';
import {getOrganizationsApi, updateOrganization} from '../../../api';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Modal from '../../common/Modal';
import TextInput from '../../common/TextInput';
import {isSpecialCharacterIncludes} from '../../../utils';
import {CONSTANTS} from '../../../constants';
import Loader from '../../common/Loader';
import useTranslations from '../../../hooks/useTranslation';

const Home = ({setCurrentPage, setOrgId, setOrgName}) => {
  const {translate} = useTranslations();
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [editedOrg, setEditedOrg] = useState({
    name: '',
    id: ''
  });

  const fetchOrgs = async () => {
    try {
      setLoading(true);
      const response = await getOrganizationsApi();
      if (response?.data?.status === 200) {
        setOrgs(response?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUpdateOrganization = async () => {
    if (editedOrg.name.length === 0) {
      setError(translate("Please enter the organization name"));
      return;
    }
    try {
      const res = await updateOrganization(editedOrg.id, editedOrg.name);
      if (res?.data?.status === 200) {
        setEditedOrg({
          name: '',
          id: ''
        });
        setModalOpen(false);
        fetchOrgs();
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchOrgs();
    return () => {
      setOrgName('');
    };
  }, []);
  return (
    <>
      <Box>
        <CssBaseline />
        <Box>
          <Typography variant="h3" mt={5} mb={5}>
            {translate("All Organizations")}
          </Typography>
        </Box>
        <Box px={3} mx={0}>
          {loading ? (
            <Loader />
          ) : (
            <>
              {orgs && orgs.length > 0 ? (
                [...orgs].map((org, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                      mt={0.5}
                    >
                      <Box sx={{textAlign: 'left', width: '70%'}}>
                        <Typography sx={{width: '100%', wordBreak: 'break-word'}} variant="h5">
                          {org.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setOrgId(org?.id);
                            setCurrentPage(2);
                            setOrgName(org?.name);
                          }}
                        >
                          <PersonAddAlt1Icon
                            fontSize="22"
                            style={{
                              fontSize: '2rem',
                              color: theme.palette.error.main
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setModalOpen(true);
                            setEditedOrg({name: org?.name, id: org?.id});
                          }}
                        >
                          <BorderColorIcon
                            style={{
                              fontSize: '22px',
                              color: theme.palette.error.main
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Box display="flex" justifyContent="center" marginTop={10}>
                  <Typography variant="h5">{translate("No Organizations found")}</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate("Update Organization")}
        buttons={[
          {name: translate("Update"), onClick: () => handleUpdateOrganization()},
          {
            name: translate("Close"),
            onClick: () => {
              setModalOpen(false);
              setEditedOrg({name: '', id: ''});
            }
          }
        ]}
      >
        <TextInput
          value={editedOrg?.name}
          placeholder={translate("Enter Organization Name")}
          maxLength={CONSTANTS.TEXT_FIELD_MAX_LENGTH}
          onChange={e => {
            setError(null);
            setEditedOrg(prev => {
              return {...prev, name: e.target.value};
            });
          }}
          label={translate("Organization Name")}
          error={editedOrg?.name && isSpecialCharacterIncludes(editedOrg?.name)}
          helperText={editedOrg?.name && isSpecialCharacterIncludes(editedOrg?.name) ? translate("Enter valid organization name") : ''}
          labelVariant="body2"
        />
        <Grid item xs={12} sx={{height: '55px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </Modal>
    </>
  );
};

export default Home;
