import axios from 'axios';
import {BASE_URL, CONSTANTS} from '../constants';
import {toast} from 'react-hot-toast';
import useTranslations from '../hooks/useTranslation';
const {translate} = useTranslations();

const state = JSON.parse(localStorage.getItem('auth-storage'));
const API = axios.create({
  baseURL: BASE_URL
});

// FOR ADDING HEADER TO EACH REQUESTS
API.interceptors.request.use(req => {
  req.headers['Content-Type'] = 'application/json';
  if (state?.state?.token) {
    req.headers['Authorization'] = state?.state?.token;
  }
  return req;
});

API.interceptors.response.use(
  async res => {
    if (res?.data?.message !== 'success' && res?.request?.responseType !== 'blob') {
      toast.success(translate(res?.data?.message), {id: 'toast_message'});
    }
    return res;
  },
  async error => {
    if (!(String(error?.config?.url).startsWith('/zipcode') && error?.config?.method === 'get')) {
      toast.error(translate(error?.response?.data?.message) || translate('Something went wrong!'), {id: 'toast_message'});
    }
    if (error.response.status === 500) {
      toast.error(translate('Something went wrong!'), {id: 'toast_message'});
      localStorage.clear();
      window.location.reload();
    }
    return error;
  }
);

// AUTH API'S
const loginUserApi = data => {
  return API.post('/login', data);
};
const checkInviteUser = inviteCode => {
  return API.get(`/signup/${inviteCode}`);
};
const registerInvitedUser = (inviteCode, data) => {
  return API.patch(`/signup/${inviteCode}`, data);
};
const registerNonInvitedUser = data => {
  return API.post(`/signup`, data);
};
const otpVerificationApi = data => {
  return API.post('/email-verification', data);
};
const resetPasswordSendOTPApi = email => {
  return API.get(`reset-password/${email}`);
};
const verifyAndResetPasswordApi = data => {
  return API.post('reset-password', data);
};

// USER API'S
const getUsersDetails = userId => {
  return API.get(`/users?userId=${userId}`);
};
const updateUsersDetails = userId => {
  return API.patch(`/users/${userId}`);
};
const inviteUser = data => {
  return API.post('/users', data);
};
const deleteUser = usersid => {
  return API.delete(`/users/${usersid}`);
};
const deleteInvitationOfUserApi = id => {
  return API.delete(`/users/${id}`);
};

// ASSESSMENT API'S
const getAssessmentsQuestions = (questions_ids, user_id, group_id) => {
  return API.get(`/questions?questions_ids=${questions_ids}&user_id=${user_id}&group_id=${group_id}`);
};
const getDiscipleQuestions = (questions_ids, user_id) => {
  return API.get(`/experience/${user_id}?questions_ids=${questions_ids}`);
};
const postAssessmentsQuestions = data => {
  return API.post('/assessment', data);
};

// GROUP API'S
const getGroupApi = (id, user_id = '') => {
  return API.get(`/group?group_id=${id}&user_id=${user_id}`);
};
const updateGroupApi = (id, data) => {
  return API.patch(`/group/${id}`, data);
};
const getLeadersGroup = () => {
  return API.get('/leaders-groups');
};
const inviteDiscipleApi = data => {
  return API.post('/group-disciple', data);
};
const sendInviteToDisciple = data => {
  return API.post('/send-invite', data);
};
const getGroupDiscipleApi = id => {
  return API.get(`/group-disciple?group_id=${id}`);
};

// ORGANIZATIONS API'S
const addOrganization = data => {
  return API.post('/organization', data);
};
const getOrganizationsApi = (id = '') => {
  return API.get(`/organization?user_id=${id}`);
};
const sendMeetingInvite = data => {
  return API.post('/group-meetings', data);
};
const updateMeetingApi = (id, data) => {
  return API.patch(`/group-meetings/${id}`, data);
};
const getMeetings = (id, user_id = '') => {
  return API.get(`/group-meetings?group_id=${id}&user_id=${user_id}`);
};
const getOutsideReadingPlanApi = id => {
  return API.get(`/group-outside-reading-plan/${id}`);
};
const updateOutsideReadingPlanApi = data => {
  return API.post('/group-outside-reading-plan', data);
};
const getBibleReadingPlanApi = id => {
  return API.get(`/group-bible-reading-plan/${id}`);
};
const updateBibleReadingPlanApi = data => {
  return API.post('/group-bible-reading-plan', data);
};
const postUpdateBibleReadingPlanApi = (id, data) => {
  return API.patch(`/group-bible-reading-plan/${id}`, data);
};
const updateOrganization = (id, name) => {
  return API.patch(`organization/${id}`, {name});
};

// LEADER API'S
const addExperienceApi = data => {
  return API.post('/experience', data);
};
const getExperienceApi = ids => {
  return API.get(`/experience?questions_ids=${ids}`);
};
const addAddressApi = data => {
  return API.post('/address', data);
};
const getAddressApi = () => {
  return API.get(`/address`);
};
const updateAddressApi = data => {
  return API.patch('/address/123', data);
};
const getAddressByZipcode = zipcode => {
  return API.get(`/zipcode?zipcode=${zipcode}`);
};

const getReportApi = data => {
  return API.post(`/report`, data);
};

// disciple apis
const deleteDiscipleApi = id => {
  return API.delete(`/group-disciple/${id}`);
};

// send email apis
const sendEmailApi = (data, type) => {
  return API.post(`/send-email?send_to=${type}`, data);
};

// reading plan apis
const discipleReadingApi = (requestedWeek, type) => {
  return API.get(`/disciple-reading/${requestedWeek}?type=${type}`);
};

const downloadBibleReadingPlan = type => {
  return API.get(`/download-bible-reading-plan?type=${type}`, {
    responseType: 'blob'
  });
};
const markChapterReadApi = data => {
  return API.post('/disciple-reading', data);
};

const getBadges = (group_disciples_id, group_id) => {
  return API.get(`/badges/${group_disciples_id}?gp_id=${group_id}`);
};

// update user role apis
const addUserRoleApi = data => {
  return API.post(`/user-role`, data);
};
const removeUserRoleApi = (id, permission, orgId) => {
  return API.patch(`/user-role/${id}`, {
    permission,
    orgId
  });
};

// admin dashboard api
const getAdminData = async orgId => {
  return API.get(`admin?organization_id=${orgId}`);
};

const getDashboardData = async () => {
  return API.get(`dashboard`);
};

const updateUserRole = async (userId, data) => {
  return API.patch(`update-user-role/${userId}`, data);
};

const getDiscipleDetailsApi = async (user_id, name) => {
  return API.get(`admin-disciple?user_id=${user_id}&name=${name}`);
};

const getLeaderDiscipleDetailsApi = async (user_id, name, orgId) => {
  return API.get(`admin-disciple/${user_id}?name=${name}&organization_id=${orgId}`);
};

const getAdminLeadersGroupApi = (id, details = '', organization = '') => {
  return API.get(`/leaders-groups/${id}?isShow=${details}&organization=${organization}`);
};

const getAdminOrgDisciplesApi = (orgId, name, isNominated = false) => {
  return API.get(`/group-disciple/${orgId}?name=${name}&is_nominated=${isNominated}`);
};
const getAdminGroupsApi = orgId => {
  return API.get(`/group/${orgId}`);
};
const markAttendenceOfGroupMeeting = data => {
  return API.post(`/meeting-attendance`, data);
};
const getAttendenceOfGroupMeeting = meeting_id => {
  return API.get(`/meeting-attendance/${meeting_id}`);
};
export {
  sendEmailApi,
  loginUserApi,
  checkInviteUser,
  registerInvitedUser,
  registerNonInvitedUser,
  otpVerificationApi,
  resetPasswordSendOTPApi,
  verifyAndResetPasswordApi,
  getUsersDetails,
  getAssessmentsQuestions,
  postAssessmentsQuestions,
  getGroupApi,
  updateGroupApi,
  inviteDiscipleApi,
  sendInviteToDisciple,
  getLeadersGroup,
  inviteUser,
  addOrganization,
  getOrganizationsApi,
  updateOrganization,
  sendMeetingInvite,
  addAddressApi,
  addExperienceApi,
  getExperienceApi,
  getAddressApi,
  updateAddressApi,
  getAddressByZipcode,
  getReportApi,
  deleteDiscipleApi,
  updateMeetingApi,
  deleteInvitationOfUserApi,
  getMeetings,
  getOutsideReadingPlanApi,
  updateOutsideReadingPlanApi,
  discipleReadingApi,
  deleteUser,
  getBibleReadingPlanApi,
  updateBibleReadingPlanApi,
  downloadBibleReadingPlan,
  addUserRoleApi,
  removeUserRoleApi,
  markChapterReadApi,
  postUpdateBibleReadingPlanApi,
  getAdminData,
  updateUsersDetails,
  updateUserRole,
  getAdminOrgDisciplesApi,
  getDiscipleDetailsApi,
  getAdminLeadersGroupApi,
  getGroupDiscipleApi,
  getLeaderDiscipleDetailsApi,
  getAdminGroupsApi,
  getDashboardData,
  getDiscipleQuestions,
  markAttendenceOfGroupMeeting,
  getAttendenceOfGroupMeeting,
  getBadges
};
