export const BASE_URL = 'https://sddev.sustainable-discipleship.com/api/v1';
export const FILE_PATH_URL = 'https://sddev.sustainable-discipleship.com/api/group/avatar';
export const FILE_PATH_PROFILE_URL = 'https://sddev.sustainable-discipleship.com/api/user/profile_avatar';
export const GCAPTCHA_CLIENT_KEY = `6LfXrBYqAAAAAMKnQ1OuFxqywJ26yJCteEqqaNF6`;

export const CONSTANTS = {
  PASSWORD_MAX_LENGTH: 8,
  COUNTDOWN: 30,
  MAX_GROUP_LENGTH: 64,
  DEBOUNCE_TIMEOUT: 500,
  MAX_PDF_FILE_SIZE: 10000000,
  MAX_IMAGE_FILE_SIZE: 10000000,
  TEXT_AREA_MAX_LENGTH: 3000,
  TEXT_FIELD_MAX_LENGTH: 256,
  OTP_LENGTH: 4,
  ORGANIZATION_LENGTH: 25,
  ZIPCODE_MAX_LENGTH: 10,
  LOCATION_MAX_LENGTH: 64,
  MOBILE_NUMBER_MAX_LENGTH: 14,
  APPLICATION_REGISTRATION_DATE: '24-07-2024',
  CONTACT_URL: 'app@sustainable-discipleship.com',
  DATE_FORMAT: 'Do MMMM, YYYY',
  // GCAPTCHA_CLIENT_KEY: `6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI`, // test key
  OUTSIDE_BOOKS_LINK: 'https://disciples.sustainable-discipleship.com',
  GET_STARTED_LINKS: {
    ONE: 'https://www.sustainable-discipleship.com/the-book/',
    TWO: 'https://sustainable-discipleship.com',
    THREE: 'https://sustainable-discipleship.com/resources'
  },
  D2I_SITE: {
    NAME: 'D2i Technology',
    URL: 'https://d2itechnology.com'
  },
  D1_MAX_BOOKS: 12,
  D2_MAX_BOOKS: 14,
  D3_MAX_BOOKS: 19,
  MESSAGES: {
    USER_UPDATED: 'User updated successfully!',
    USER_EXIST_WITH_NAME: 'User already exist with this name!',
    SOMETHING_WRONG: 'Something went wrong!',
    ALL_FIELDS_REQUIRED: 'All fields are required!',
    CANNOT_CONSIST_ALL_ZEROES: 'Phone number cannot consist of all zeros.',
    VALID_PHONE_NUMBER: 'Enter valid mobile number',
    MIN_MAX_PHONE_LENGTH: 'Enter phone number between 8 to 14 digits',
    ADD_ATLEAST_LEADER: 'Please add at-least one leader!',
    ADD_ATLEAST_ADMIN: 'Please add at-least one admin!',
    ADD_ATLEAST_DISCIPLE: 'Please add at-least one disciple!',
    PDF_ALLOWED: 'Only pdf file allowed!',
    IMAGE_ALLOWED_ERROR: 'Only image file allowed!',
    FILE_LARGE: 'File is too large (not more than 10 mb)',
    FILE_LARGE_IMAGE: 'File is too large (not more than 10 mb)',
    INVALID_EMAIL: 'Invalid email address!',
    INVALID_CRED: 'Invalid login credentials!',
    PASSWORD_INFO: {
      LENGTH: 'Should be mininum 8 characters.',
      SPECIAL_CHARACTERS: 'Should contain special character.',
      NUMBER: 'Should contain number.',
      UPPERCASE: 'Should contain uppercase.',
      LOWERCASE: 'Should contain lowercase.',
      MATCH: 'Password and confirm password should be same.'
    },
    PASSWORD_MISMATCH: 'Password does not meet the criteria!',
    INVALID_ZIPCODE: 'Invalid zipcode!',
    INVALID_LOCATION: 'Invalid location!',
    SELECT_ATLEAST_DISC: 'Select atleast one disciple!',
    ATLEAST_SINGLE_FIELD: 'Please fill at-least single field!',
    ONLY_INVITED_USER_REGISTER: 'Only invited user can register for now!',
    VALID_GROUP_NAME: 'Please enter valid group name!',
    VALID_PHONE_NUMBER: 'Please enter valid phone number!',
    STAYING_IN: 'Congrats they have decided to stay',
    GROUP_UPDATED: 'Group updated successfully',
    EMAIL_REQUIRED: 'Email is required!',
    SUBJECT_AND_BODY_REQUIRED: 'Subject and Body are required!',
    GROUP_REQUIRED: 'Group name is required!',
    ORGANIZATION_REQUIRED: 'Organization name is required!',
    ENTER_ORGANIZATION_NAME: 'Please enter the organization name',
    GROUP_EXIST: 'Group already exists',
    GROUP_EXIST_WITH_NAME: 'Group already exist with this name',
    SELECT_REPORT: 'Please select the report',
    SALVATION_YEAR_BIRTHDAY: 'Salvation year must be after birthday'
  },
  DISCIPLE_JOURNEY_IDS: {
    JOURNEY_1: '51,52,53,54',
    JOURNEY_2: '55,56',
    LEADER_JOURNEY: '1,2,3,4,5,6',
    ASSEMENT_ONE: [7,8]
  },
  QUESTION_OPTIONS: {
    DISCIPLE_OPTIONS: {
      55: [
        {id: 1, label: 'Which one are you? Pick just one.', options: ['Introvert', 'Extrovert', 'Ambivert']},
        {id: 2, label: '', options: ['Go', 'Send']},
        {id: 3, label: '', options: ['Lead', 'Follow']},
        {id: 4, label: '', options: ['Tell', 'Listen']},
        {id: 5, label: 'What type of learner are you?', options: ['Visual', 'Auditory', 'Both']},
        {id: 6, label: 'Which best describes how you feel or process information?', options: ['Emotional', 'Engineer', 'Activist']}
      ]
    }
  },
  LEADER_JOURNEY_QUESTIONS: [
    {
      id: 1,
      width: '100%',
      label: "I'VE ATTENDED",
      type: 'checkbox',
      options: ['Live Workshop', 'Virtual Workshop', 'N/A'],
      selectedAnswers: []
    },
    {
      id: 2,
      width: '100%',
      label: "I'VE READ",
      type: 'checkbox',
      options: ['How to Make Disciples', 'Well Made Well Done', 'N/A'],
      selectedAnswers: []
    },
    {
      id: 3,
      width: '50%',
      label: "I'VE PARTICIPATED IN",
      type: 'checkbox',
      options: ['D1', 'D2', 'D3', 'N/A', 'Outside Discipleship'],
      selectedAnswers: []
    },
    {
      id: 4,
      width: '50%',
      label: "I'VE LEAD",
      type: 'checkbox',
      options: ['D1', 'D2', 'D3', 'N/A'],
      selectedAnswers: []
    },
    ,
    {
      id: 5,
      width: '50%',
      label: "I'VE BEEN COACHED",
      type: 'radio',
      options: ['Yes', 'No'],
      selectedAnswers: ''
    },
    ,
    {
      id: 6,
      width: '50%',
      label: 'I WANT COACHING',
      type: 'radio',
      options: ['Yes', 'No'],
      selectedAnswers: ''
    }
  ],
  ASSESSMENT_QUESTIONS_IDS: {
    ASSESSMENT_1: '[7,8]',
    ASSESSMENT_2: '[9,10,11]',
    ASSESSMENT_3: '[13,14,15,16,17,18,19,44,45,46,47,48,49,50]',
    ASSESSMENT_4: '[20,21]',
    ASSESSMENT_5: '[23,24,25,26,27]',
    ASSESSMENT_6: '[28]',
    ASSESSMENT_7: '[29,30,31]',
    ASSESSMENT_8: '[32,33,34,35]',
    ASSESSMENT_9: '[32,33,34,35]',
    ASSESSMENT_10: '[36,42,43,37]',
    ASSESSMENT_11: '[38,39]',
    ASSESSMENT_12: '[40,41,57]'
  },
  DOING_ASSESSMENT: [
    {
      value: 'Nothing (Simply a Consumer, Getting a Paycheck)',
      label: 'Nothing (Simply a Consumer, Getting a Paycheck)',
      score: 0
    },
    {
      value: 'Consistently Applying God’s Truths (New and Old)',
      label: 'Consistently Applying God’s Truths (New and Old)',
      score: 2
    },
    {value: 'Eager to be Taught', label: 'Eager to be Taught', score: 3},
    {
      value: 'Volunteer (Parking, Greeting, Kids, Helping Outside of Job Description)',
      label: 'Volunteer (Parking, Greeting, Kids, Helping Outside of Job Description)',
      score: 6
    },
    {
      value: 'Learning to Learn (Following On Their Own, Self-Starter)',
      label: 'Learning to Learn (Following On Their Own, Self-Starter)',
      score: 7
    },
    {
      value: 'Learning to Lead (Leading Volunteers, Assistant Manager)',
      label: 'Learning to Lead (Leading Volunteers, Assistant Manager)',
      score: 10
    },
    {
      value: 'Leading Leaders (Pastor or Senior Leader, Executive)',
      label: 'Leading Leaders (Pastor or Senior Leader, Executive)',
      score: 11
    },
    {
      value: 'Influencing/Teaching (Small Groups, Visionary, Management)',
      label: 'Influencing/Teaching (Small Groups, Visionary, Management)',
      score: 14
    },
    {
      value: 'Reaching the World (Missionary, Blogging/Speaking)',
      label: 'Reaching the World (Missionary, Blogging/Speaking)',
      score: 14
    }
  ],
  BE_ASSESSMENT: [
    {
      value: 'Not Hearing from God or Bible',
      label: 'Not Hearing from God or Bible',
      score: 0
    },
    {
      value: 'Hearing, Seeing and Not Doing (Guilty? Idle?)',
      label: 'Hearing, Seeing and Not Doing (Guilty? Idle?)',
      score: 1
    },
    {
      value: 'Hearing, Applying, and Getting It',
      label: 'Hearing, Applying, and Getting It',
      score: 4
    },
    {
      value: 'Asking to Serve, Compelled to Join God in His Work',
      label: 'Asking to Serve, Compelled to Join God in His Work',
      score: 5
    },
    {
      value: 'They know what to do and why to do it',
      label: 'They know what to do and why to do it',
      score: 8
    },
    {
      value: 'They are following on their own (you don’t have to push much anymore)',
      label: 'They are following on their own (you don’t have to push much anymore)',
      score: 9
    },
    {
      value: 'They are all in on following God and serving God',
      label: 'They are all in on following God and serving God',
      score: 9
    },
    {
      value: 'They are a servant not just serving (always available for God’s greater plan.)',
      label: 'They are a servant not just serving (always available for God’s greater plan.)',
      score: 12
    },
    {
      value: 'They are focused on championing God’s kingdom. It isn’t about them or theirs.',
      label: 'They are focused on championing God’s kingdom. It isn’t about them or theirs.',
      score: 14
    }
  ],
  STEP_DROPDOWNS: [
    {label: '0', value: 0},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12},
    {label: '13', value: 13},
    {label: '14', value: 14}
  ],
  REGEX: {
    ONLY_APHANUMERIC: /^[a-zA-Z0-9]+$/,
    NAME_REGEX: /[!@#$%^&*()_+\=\[\]{};:"\\|,<>\/?]/,
    PHONE_NUMBER: /^\d{8,14}$/
  },
  USER_ROLES: {
    SUPERADMIN: 0,
    ADMIN: 1,
    LEADER: 2,
    DISCIPLE: 3
  },
  ASSESSMENT_STEP_MESSAGES: {
    13: `They have regular highlights. They are hearing “to dos” and “not dos” from the Word. When
    asked, “How do you apply this?” ,they can see a clear action plan and they are adjust to God’s
    truths each time rather than pushing back.`,
    15: `Their action based on conviction has shown them a better way. They now know that they don’t
    know everything. They are hungry to learn and becoming enlightened. The Word is
    transforming their mind, their thoughts, and decisions.`,
    17: `They are feeling compelled to get into the game. Compelled to do something with this thriving
    faith. They ask for something to do for the Body and are doing it. They are showing up to serve
    everywhere.`,
    19: `They are learning on their own. They have learned to learn and know how to find what they
    don’t know. They are prepared and confident when it comes to making decisions based on
    God’s eternal truths.`,
    45: `They are all in to follow Christ - however, wherever. No questions asked. If they moved to
    Timbuktu, they would find a church or start one and follow Christ. They are ready to take the
    first steps of leadership in the Body of Christ. They are humble, waiting their turn, and want to
    learn.`,
    47: `They have learned to lead and are successfully leading others in the Body of Christ on their
    own. They have seen that it is a privilege to serve God. They aren’t just serving, they’re
    servants, waiting on Him, to do only and anything He calls them to do.`,
    49: `They are a servant of the Most High King and they realize the Kingdom is not about their home,
    job, church, or thing. They are invested in His Kingdom so much that they are willing to
    separate and go serve Him wherever, whenever.`
  },
  READINGS: {
    MYSTICAL_CHAPTER: {
      label: 'Mystical Chapters',
      value: 'Mystical Chapters'
    }
  },
  BADGES: {
    WINNIER_ONE_STAR: 'winner-one',
    WINNIER_TWO_STAR: 'winner-two',
    WINNIER_THREE_STAR: 'winner-three',
    GOODJOB_ONE_STAR: 'good-job-one',
    GOODJOB_TWO_STAR: 'good-job-one',
    GOODJOB_THREE_STAR: 'good-job-one'
  }
};
