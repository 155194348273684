import React from 'react';
import useTranslations from '../hooks/useTranslation';

const LanguageSwitcher = () => {
  const {switchLanguage, language} = useTranslations(); // Get the switchLanguage function from the hook

  return (
    <div>
      <button type="button" onClick={() => switchLanguage('en')} style={{fontWeight: language === 'en' ? 'bold' : 'normal'}}>
        English
      </button>
      <button type="button" onClick={() => switchLanguage('hi')} style={{fontWeight: language === 'hi' ? 'bold' : 'normal'}}>
        Hindi
      </button>
      <button type="button" onClick={() => switchLanguage('es')} style={{fontWeight: language === 'es' ? 'bold' : 'normal'}}>
        Español
      </button>
    </div>
  );
};

export default LanguageSwitcher;
