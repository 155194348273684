import React, {useState} from 'react';
import {Box, Button, IconButton, List, ListItemButton, ListItemText, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {deleteUser} from '../../../api';
import Modal from '../../../components/common/Modal';
import Loader from '../../../components/common/Loader';
import useTranslations from '../../../hooks/useTranslation';

const ItemList = ({items, handleClick, selectedItem, height, title, deleteIcon = false, reloadData, loader, marginBottom}) => {
  const {translate} = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [user, setUser] = useState(selectedItem);

  const handleOpenModal = item => {
    setToDelete(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setToDelete(null);
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (toDelete) {
      handleDelete(toDelete);
      handleCloseModal();
    }
  };

  const handleDelete = async id => {
    try {
      const res = await deleteUser(id);
      if (res?.data?.status === 200) {
        if (reloadData) {
          reloadData();
        }
      }
    } catch (error) {}
  };
  return (
    <Box width="100%" height={height} overflow="auto" bgcolor="#FFF" borderRadius="12px" marginBottom={marginBottom}>
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          {title && (
            <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold" textAlign="left" pl={3} pt={3}>
              {title}
            </Typography>
          )}
          <List>
            {items &&
              items.length !== 0 &&
              items.map((item, index) => (
                <ListItemButton
                  key={index}
                  selected={selectedItem === item}
                  onClick={() => handleClick(item)}
                  sx={{
                    paddingLeft: '24px',
                    '&.Mui-selected': {
                      color: '#d32f2f'
                    }
                  }}
                >
                  <ListItemText
                    primary={item?.name ? item?.name : item?.first_name + item?.last_name}
                    primaryTypographyProps={{
                      sx: {
                        color: '#000'
                      }
                    }}
                  />
                  {deleteIcon === 'true' && (
                    <IconButton>
                      <DeleteIcon style={{color: '#d32f2f'}} onClick={() => handleOpenModal(item.user_id || item.id)} />
                    </IconButton>
                  )}
                </ListItemButton>
              ))}
          </List>

          <Modal backgroundColor="white" color="black" open={isModalOpen} onClose={handleCloseModal} title={translate("Confirm Delete")}>
            <Box>
              <Typography variant="body1" mb={4} color="black">
                {translate("Are you sure you want to delete ")}{user?.name}?
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="error" onClick={handleCloseModal} sx={{mr: 1}}>
                  {translate("Cancel")}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleConfirmDelete}>
                  {translate("Delete")}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default ItemList;
