import React, {useEffect, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {getAdminGroupsApi, getAdminLeadersGroupApi, getGroupDiscipleApi, getMeetings} from '../../api';
import AdminStructure from './common/AdminStructure';
import useAdminStore from '../../store/adminStore';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import {capitalizeWords} from '../../utils';
import {CONSTANTS} from '../../constants';
import useTranslations from '../../hooks/useTranslation';

function Groups() {
  const {translate} = useTranslations();
  const {adminPanelData, organizationSelected} = useAdminStore(state => state);
  const [selectedGroup, setSelectedGroup] = useState();
  const [groups, setGroups] = useState([]);
  const [meetings, setMeetings] = useState();
  const [disciples, setDisciples] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [searchGroups, setSearchGroups] = useState([]);
  const [groupLeader, setGroupLeader] = useState();
  const [loader, setLoader] = useState(false);

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleGroupClick = async group => {
    groupMeetingsById(group?.id);
    getGroupDisciplesById(group?.id);
    getGroupLeader(group?.id);
    setSelectedGroup(group);
  };

  const getGroups = async () => {
    try {
      setLoadingPage(true);
      const resp = await getAdminGroupsApi(organizationSelected);
      if (resp?.status == 200) {
        setGroups(resp?.data?.data);
        setSearchGroups(resp?.data?.data);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  const groupMeetingsById = async id => {
    setLoader(true);
    try {
      const res = await getMeetings(id);
      if (res?.data?.status === 200) {
        setMeetings(res?.data?.data);
      } else {
        setMeetings({});
      }
      setLoader(false);
    } catch (error) {
      setMeetings({});
    }
    setLoader(false);
  };

  const getGroupDisciplesById = async id => {
    setLoader(true);
    try {
      const res = await getGroupDiscipleApi(id);
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data);
      }
      setLoader(false);
    } catch (error) {}
    setLoader(false);
  };

  const getLeaders = async () => {
    try {
      const res = await getAdminLeadersGroupApi(organizationSelected);
      if (res?.data?.status == 200) {
        setLeaders(res?.data?.data);
      }
    } catch (error) {}
  };

  const getGroupLeader = async id => {
    try {
      const resp = await getAdminLeadersGroupApi(id, 'leader');
      if (resp?.data?.status == 200) {
        setGroupLeader(resp?.data?.data);
      }
    } catch (error) {}
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setGroups(searchGroups);
    } else {
      const filteredGroups = searchGroups.filter(group => group?.name?.toLowerCase().includes(value.toLowerCase()));
      setGroups(filteredGroups);
    }
  };

  useEffect(() => {
    getGroups();
    getLeaders();
  }, [organizationSelected]);

  // Calculate the counts of each status group
  const activeGroupsCount = groups ? groups.filter(group => group.status === 'ACTIVE').length : 0;
  const completedGroupsCount = groups ? groups.filter(group => ['COMPLETED', 'ARCHIEVED'].includes(group.status)).length : 0;

  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: isSmallScreen ? 'auto' : 'hidden'
          }}
        >
          <AdminStructure isSearch={true} searchHandler={searchHandler} heading={translate("Groups")}>
            {loadingPage ? (
              <Loader color="#9e0001" />
            ) : groups?.length === 0 ? (
              <NoDataFoundMessage mt={5} message={translate("There is no group for this organization")} />
            ) : (
              <>
                <Box
                  display={'flex'}
                  // justifyContent={"space-between"}
                  px={2}
                  gap={2}
                  sx={{
                    height: {
                      md: '85vh',
                      xs: 'auto',
                      sm: 'auto'
                    },
                    flexDirection: {
                      md: 'row',
                      sm: 'column',
                      xs: 'column'
                    },
                    justifyContent: {
                      md: 'space-between'
                    }
                  }}
                >
                  <Box
                    width="100%"
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    sx={{
                      marginTop: {
                        xs: '6px',
                        sm: '6px',
                        md: 0
                      }
                    }}
                  >
                    <ItemList items={groups?.filter(group => ['ACTIVE'].includes(group.status))} handleClick={handleGroupClick} selectedItem={selectedGroup} height={'50%'} title={`${translate("Active Groups")} (${activeGroupsCount})`} />
                    <ItemList items={groups?.filter(group => ['COMPLETED', 'ARCHIEVED'].includes(group.status))} handleClick={handleGroupClick} selectedItem={selectedGroup} height={'50%'} title={`${translate("Completed Groups")} (${completedGroupsCount})`} />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{
                      justifyContent: {
                        md: 'space-between'
                      }
                    }}
                  >
                    {selectedGroup && (
                      <>
                        <ItemDetails
                          leaders={leaders}
                          height="45%" // set height for md screens (xs and sm are defaulted to auto)
                          title={selectedGroup?.name}
                          selectedGroup={selectedGroup}
                          details={[
                            {
                              name: translate("Start Date: "),
                              detail: meetings?.starting_time ? moment(meetings?.starting_time).format(CONSTANTS.DATE_FORMAT) : 'NA'
                            },
                            {
                              name: translate("Day of Week: "),
                              detail: meetings?.meeting_day ? capitalizeWords(meetings?.meeting_day) : 'NA'
                            },
                            {
                              name: translate("Location: "),
                              detail: meetings?.meeting_place ? meetings?.meeting_place : 'NA'
                            },
                            {
                              name: translate("Number of Weeks: "),
                              detail: meetings?.meeting_weeks ? meetings?.meeting_weeks : 'NA'
                            },
                            {
                              name: translate("Completed: "),
                              detail: meetings?.completed_disciples?.length ? meetings?.completed_disciples?.length : '0'
                            },
                            {
                              name: translate("Dropped: "),
                              detail: meetings?.dropped_disciples?.length ? meetings?.dropped_disciples?.length : '0'
                            },
                            {
                              name: translate("Leader: "),
                              icon: selectedGroup?.status !== 'COMPLETED',
                              leaders: groupLeader
                            }
                          ]}
                          loader={loader}
                          setSelectedGroup={setSelectedGroup}
                        />
                        <ItemList title={`${selectedGroup?.name} ${translate("Disciples")}`} items={disciples} handleClick={() => {}} selectedItem={() => {}} height="45%" marginBottom={isSmallScreen ? 2 : 0} loader={loader} />
                      </>
                    )}
                  </Box>
                </Box>
                {/* hide on mobile screen */}
                {/* <Box sx={{
                  display: {
                    md: "flex",
                    xs: "none",
                    sm: "none"
                  }
                }} justifyContent="space-between" px={2}>
                  <Typography
                    variant="h6"
                    color="#000"
                    sx={{
                      position: "fixed",
                      bottom: 10,
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <TaskAltIcon></TaskAltIcon> Include Completed Groups
                  </Typography>
                </Box> */}
              </>
            )}
          </AdminStructure>
        </div>
      </Box>
    </>
  );
}

export default Groups;
